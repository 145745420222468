import React from 'react';
import FAQ from './faq';
import '../index.css'; // Импорт CSS файла

function Services() {
  return (
    <section className="services" id='services'>
      <div className="service-header">
        <h2>УСЛУГИ</h2>
        <a href="#" className="service-book">Скачать полный прайс-лист</a>
      </div>
      <div className="faq_container">
        <FAQ
          question="Подкасты под ключ"
          answer="Мы предлагаем высококачественные оборудования, помощь профессионалов и разные локации для вашего удобства! Качественная акустика, кондиционер, чистота студии и приятное освещение сделает ваш опыт в нашей студии самым приятным и продуктивным!"
          link="https://t.me/msaitu"
        />
        <FAQ
          question="Reels Съемки"
          answer="Мы предлагаем высококачественные оборудования, помощь профессионалов и разные локации для вашего удобства! Качественная акустика, кондиционер, чистота студии и приятное освещение сделает ваш опыт в нашей студии самым приятным и продуктивным!"
          link="https://www.instagram.com/mediastudio.kz?igsh=c2VmNGgwOTIzeWx2"
        />
        <FAQ
          question="Вебинары"
          answer="Мы предлагаем высококачественные оборудования, помощь профессионалов и разные локации для вашего удобства! Качественная акустика, кондиционер, чистота студии и приятное освещение сделает ваш опыт в нашей студии самым приятным и продуктивным!"
          link="https://t.me/msaitu"
        />
        <FAQ
          question="Видеолекции"
          answer="Мы предлагаем высококачественные оборудования, помощь профессионалов и разные локации для вашего удобства! Качественная акустика, кондиционер, чистота студии и приятное освещение сделает ваш опыт в нашей студии самым приятным и продуктивным!"
          link="https://t.me/msaitu"
        />
      </div>
    </section>
  );
}

export default Services;
